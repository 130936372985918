<template>
  <div class="user-login-view user-view">
    <!-- <user-header />
    <div :class="['error-tips', {hide: !message, show: !!message}]">
      <a-alert v-if="message" :message="message" banner closable @close="onAlertClose" type="error" />
    </div> -->
    <div class="topText">平台登录</div>
    <div class="rightBox">
      <div class="tabTouch">
        <div v-for="(item, index) in activeList" :key="index" :class="[tabIndex != index ? 'touchOne' : 'touchTwo']"
          @click="touchTab(index)">
          {{ item.name }}
        </div>
      </div>
      <div v-if="!tabIndex">
        <a-form-model :model="values" :rules="rules" ref="loginForm" class="login-form">
          <a-form-model-item prop="phone">
            <a-input :size="size" v-model="values.phone" placeholder="账号" class="iptHeight">
              <!-- <a-icon slot="prefix" type="user" class="C999" /> -->
            </a-input>

          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input :size="size" v-model="values.password" type="password" placeholder="密码" class="iptHeight">
              <!-- <a-icon slot="prefix" type="lock" class="C999" /> -->
            </a-input>
          </a-form-model-item>

          <!-- <a-form-model-item prop="auto">
          <a-checkbox v-model="values.auto">自动登录</a-checkbox>
        </a-form-model-item> -->

          <a-form-model-item class="TAC">
            <a-button :size="size" :loading="loading" @click="onLogin" type="primary" class="W100">
              登录
            </a-button>
          </a-form-model-item>

          <!-- <a-form-model-item>
          <div class="FB FBJC-SB">
            <router-link to="/user/reset-password">
              忘记密码
            </router-link>
            <router-link to="/user/register">
              注册账户
            </router-link>
          </div>
        </a-form-model-item> -->
        </a-form-model>
      </div>
      <!-- 手机号登录 -->
      <div v-else>
        <a-form ref="anthorForm" :model="anthorForm" :rules="anthorRules" class="login-form">
          <a-form-item prop="phone">
            <a-input v-model="anthorForm.phone" type="text" max-length="11" placeholder="请输入手机号码" class="iptHeight" />
          </a-form-item>
          <a-form-item prop="code">
            <a-input v-model="anthorForm.code" type="text" class="iptHeight" style="width: 63%" placeholder="请输入验证码"
              @keyup.enter.native="anhandleLogin" />
            <div class="login-code">
              <a-button class="codeBtn" type="primary" :loading="codeloading" @click="getCode(anthorForm.phone)">
                <span v-if="!codeloading">获取验证码</span>
                <span v-else>{{ count }} s</span>
              </a-button>
            </div>
          </a-form-item>
          <div class="boxBottom">
            <a-checkbox v-model="values.auto">自动登录</a-checkbox>
            <!-- <div class="forgetPsd" @click="runForgetPassord">忘记密码</div> -->
          </div>

          <a-form-item style="width: 100%">
            <a-button :loading="loading" type="primary" style="width: 100%; border-radius: 20px"
              @click.native.prevent="anhandleLogin">
              <span v-if="!loading">登录</span>
              <span v-else>登 录 中...</span>
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex'
import * as Encryptor from '@/utils/encryptor'

export default {
  components: {
    // UserHeader
  },
  data () {
    return {
      // 提交控制
      loading: false,
      // 表单尺寸，账户模块的表单都统一使用大号的
      size: 'large',
      // 表单值
      values: {
        phone: '', //18516728646
        password: ''//123456
      },
      // 接口返回的错误提示
      message: '',
      // 校验规则
      rules: {
        phone: [{
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('请输入账号'))
            }
            return callback()
          }
        }],
        password: [{
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('请输入密码'))
            }
            // 密码强度校验
            // if (!this.$utils.testPassword(value)) {
            //   return callback(new Error('密码必须是 8 至 16 位大小写字母数字下划线组合'))
            // }
            return callback()
          }
        }]
      },
      tabIndex: 0,
      activeList: [{
        name: '账户密码登录',
        id: 0
      }
      ],
      anthorForm: {
        username: '',
        code: '',
        rememberMe: false
      },
      anthorRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '手机号不能为空'
        }],
        code: [{
          required: true,
          trigger: 'blur',
          message: '验证码不能为空'
        }]
      },
      getPassword: true
    }
  },
  methods: {
    ...mapActions(['login', 'getOss']),
    // 点击tab
    touchTab (index) {
      this.tabIndex = index
    },
    onLogin () {
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.values.password = Encryptor.encrypt(this.values.password);
          this.login(this.values).then(() => {
            const {
              query
            } = this.$route
            this.getOss().then(() => {
              this.loading = false
              this.$router.push(query.redirect || '/')
            })
          }).catch(e => {
            this.message = e.message || '账号或密码错误'
          }).finally(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    onAlertClose () {
      this.message = ''
    }
  }
}
</script>
<style lang="less" scoped>
.user-login-view {
  width: 360px !important;
  height: 400px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .error-tips {
    margin: 33px 0 10px;
    height: 37px;
    transition: opacity 0.25s ease-in;

    &.hide {
      opacity: 0;
    }

    &.show {
      opacity: 1;
    }
  }
}

.topText {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -70px;
  left: 105px;
}

.rightBox {
  margin: auto;

  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
  }
}

.tabTouch {
  display: flex;
  justify-content: space-around;
  align-content: center;
  width: 360px;
  margin-left: 8px;
}

.touchOne {
  color: #666666;
  padding: 20px;
}

.touchTwo {
  border-bottom: 1px solid #1890ff;
  padding: 20px;
  color: #1890ff;
}

.boxBottom {
  display: flex;
  justify-content: space-between;

  .forgetPsd {
    font-size: 14px;
    color: #1890ff;
    cursor: pointer;
  }
}

.title {
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  padding: 25px 25px 5px 25px;

  .ant-input-affix-wrapper {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.iptHeight {
  height: 54px !important;

  input {
    height: 54px !important;
  }

  span {
    display: flex;
    align-items: center;
  }

  .input-icon {
    width: 20px;
    height: 20px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  float: right;

  .codeBtn {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background: #eeeeee;
    color: #666666;
    border: none;
  }

  img {
    vertical-align: middle;
  }
}

.box-card {
  width: 100%;
  height: 100%;

  .clearfix {
    width: 50%;
    margin: 10% auto;
  }
}

.login-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
